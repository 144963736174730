import React from 'react';
import { BrowserRouter as Router, Route, Link, Outlet, Routes } from 'react-router-dom';
import Links from './Links';
import Account from './Account';
import Footer from './Footer';
import './tailwind-styles.css';

function App() {
    return (
        <Router>
            <div>
                {/* Add navigation links */}

                <div className="container mx-auto">
                    <div className="navbar bg-base-100">
                        <div className="flex-1">
                            <Link to="/" className="btn btn-ghost normal-case text-4xl font-header-font text-primary">ugh.lol</Link>
                        </div>
                        <div className="flex-none gap-2">
                            <div className="dropdown dropdown-end">
                                <label tabindex="0" className="btn btn-ghost btn-circle avatar">
                                    <div className="w-10 rounded-full">
                                        <img src="profile-placeholder.png" />
                                    </div>
                                </label>
                                <ul tabindex="0"
                                    className="mt-3 p-2 shadow menu menu-compact dropdown-content bg-base-100 rounded-box w-52">
                                    <li><Link to="/account">Account</Link></li>
                                    <li><a
                                        href="https://login.ugh.lol/logout?client_id=70h6cm1g4d2mmu95des5m91o0v&logout_uri=https://ugh.lol/">Logout</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Define routes for components */}
                <Routes>
                    <Route path="/" element={<Links />} />
                    <Route path="/account" element={<Account />} />
                </Routes>
                <Footer />
            </div>
        </Router>
    );
}

export default App;

