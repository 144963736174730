import React, { createContext, useState, useContext, useEffect } from 'react';

const AuthContext = createContext();

const isDevelopment = () => process.env.NODE_ENV === 'development';

export const AuthProvider = ({ children }) => {
    const [authData, setAuthData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const storedAuthData = JSON.parse(localStorage.getItem('auth'));
        if (storedAuthData) {
            setAuthData(storedAuthData);
        }
    }, []);

    useEffect(() => {
        if (!authData) return;

        const checkExpiration = () => {
            if (Date.now() > authData.expires_at) {
                refreshToken();
            }
        };

        const interval = setInterval(checkExpiration, 60000); // Check every minute

        return () => {
            clearInterval(interval);
        };
    }, [authData]);



    useEffect(() => {
        if (!authData && window.location.search.includes('code=')) {
            setLoading(true);
            const urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get('code');
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: new URLSearchParams({
                    grant_type: 'authorization_code',
                    code: code,
                    client_id: '70h6cm1g4d2mmu95des5m91o0v',
                    redirect_uri: 'https://app.ugh.lol/',
                }),
            };
            fetch('https://login.ugh.lol/oauth2/token', requestOptions)
                .then(response => response.json())
                .then(data => {
                    const newAuth = {
                        id_token: data.id_token,
                        access_token: data.access_token,
                        refresh_token: data.refresh_token,
                        expires_at: Date.now() + data.expires_in * 1000,
                        token_type: data.token_type,
                    };
                    setAuthData(newAuth);
                    localStorage.setItem('auth', JSON.stringify(newAuth));
                    setLoading(false);
                })

                .catch(error => {
                    console.error('Error fetching tokens:', error);
                    setLoading(false);
                    setError(error);
                });
        }
    }, []);

    const refreshToken = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: new URLSearchParams({
                grant_type: 'refresh_token',
                refresh_token: authData.refresh_token,
                client_id: '70h6cm1g4d2mmu95des5m91o0v',
            }),
        };

        fetch('https://login.ugh.lol/oauth2/token', requestOptions)
            .then(response => response.json())
            .then(data => {
                const newAuth = {
                    ...authData,
                    id_token: data.id_token,
                    access_token: data.access_token,
                    expires_at: Date.now() + data.expires_in * 1000,
                    token_type: data.token_type,
                };
                setAuthData(newAuth);
                localStorage.setItem('auth', JSON.stringify(newAuth));
                setLoading(false);
            })
            .catch(error => {
                console.error('Error refreshing tokens:', error);
                setLoading(false);
                setError(error);
            });
    };


    const value = {
        authData,
        setAuthData,
        loading,
        setLoading,
        error,
        setError,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};


export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

