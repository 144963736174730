
function Footer() {
  return (
    <div className="container mx-auto">
      <div className="p-4 flex flex-col items-center justify-center md:flex-row md:justify-between ">
        <p className="text-sm text-gray-600">v1.0 © 2023 ugh.lol</p>
        <div className="mt-2 md:mt-0">
        </div>
      </div>
    </div>
  );
}

export default Footer;
