import React from 'react';

function Account() {
    return (
        <div className="container mx-auto">
            <h1>Account</h1>
            {/* Your profile page content goes here */}
        </div>
    );
}

export default Account;

