import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import './tailwind-styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsAlt, faArrowsRotate, faTrash, faCopy, faCircleInfo } from '@fortawesome/free-solid-svg-icons';

function Links() {
    const { authData, loading, error } = useAuth();
    const [data, setData] = React.useState({});
    const [url, setUrl] = useState('');
    const [shortUrl, setShortUrl] = useState('');
    const isDevelopment = () => process.env.NODE_ENV === 'development';
    const [expandedKey, setExpandedKey] = useState(null);
    const [showToast, setShowToast] = useState(false);

    const testData = {
        abcd: {
            url: 'https://example.com/some/long/url',
            clicks: 10,
        },
        efgh: {
            url: 'https://another.example.com/another/long/url',
            clicks: 5,
        },
        ijkl: {
            url: 'https://sample.com/path/long/url',
            clicks: 15,
        },
        mnop: {
            url: 'https://demo.com/here/long/url',
            clicks: 7,
        },
        qrst: {
            url: 'https://example.org/unique/long/url',
            clicks: 12,
        },
        uvwx: {
            url: 'https://example.net/this/long/url',
            clicks: 3,
        },
        yz01: {
            url: 'https://test.com/sample/long/url',
            clicks: 9,
        },
        ab23: {
            url: 'https://website.com/different/long/url',
            clicks: 17,
        },
        cd45: {
            url: 'https://example.io/another/path/long/url',
            clicks: 1,
        },
        ef67: {
            url: 'https://example.co/final/long/url',
            clicks: 6,
        },
    };


    useEffect(() => {
        if (isDevelopment()) {
            setData(testData);
            setShortUrl('http://localhost:3000/abcd');
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [authData]);

    useEffect(() => {
        if (showToast) {
            const timer = setTimeout(() => {
                setShowToast(false);
            }, 1000); // Hide toast after 1 second

            return () => {
                clearTimeout(timer);
            };
        }
    }, [showToast]);


    const handleSubmit = (e) => {
        e.preventDefault();
        fetch('https://ugh.lol/app/d', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + authData.id_token,
                'accesstoken': authData.access_token,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: `url=${url}`,
        })
            .then(response => response.json())
            .then(data => {
                console.log(data);
                console.log('success');
                setShortUrl(data['url']);

                setTimeout(() => {
                    fetchData();
                }, 1000);
            })
            .catch(error => {
                console.error(error);
                console.log('error');
            });
    };


    const handleDelete = (shorthash) => {
        const url = `https://ugh.lol/app/d?shorthash=${encodeURIComponent(shorthash)}`;
        fetch(url, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + authData.id_token,
                'accesstoken': authData.access_token,
            },
        })
            .then(response => {
                if (response.ok) {
                    console.log('success');
                    fetchData();
                } else {
                    response.json().then(data => {
                        console.error('Error:', data.error);
                    }).catch(error => {
                        console.error('Error parsing JSON:', error);
                    });
                    console.log('error');
                }
            })
            .catch(error => {
                console.error('Network error:', error);
                console.log('error');
            });
    };



    const fetchData = () => {
        if (authData && authData.access_token && authData.id_token) {
            console.log('Fetching with auth:', authData);
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + authData.id_token,
                    'accesstoken': authData.access_token,
                },
            };
            fetch('https://ugh.lol/app/d', requestOptions)
                .then(response => {
                    console.log('Response:', response);
                    return response.json();
                })
                .then(d => {
                    console.log('Data:', d);
                    setData(d);
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }
    };



    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        console.log('Copied to clipboard:', text);
        setShowToast(true);
    };

    return (
        <div className="links-container">

            <div className="create-link-container">
                <div className="px-4 py-5 my-5 text-center">
                    <div className="col-lg-6 mx-auto">
                        <form onSubmit={handleSubmit}>
                            <div className="form-row">
                                <label htmlFor="url" className="url-input">Enter a long URL to shrink</label>
                            </div>
                            <div className="form-row input-group justify-center mt-2 mb-2">
                                <input id="url-input" type="text" className='input input-bordered bg-white' name="url" value={url} onChange={(e) => setUrl(e.target.value)} />
                                <button id="url-submit" type="submit" className="btn btn-secondary">Shrink!</button>
                            </div>
                        </form>
                        {shortUrl && (
                            <div className="url-response mt-4">
                                <div className="indicator">
                                    <div className="input-group">
                                        <button
                                            className="btn btn-sm btn-outline"
                                            onClick={() => copyToClipboard(shortUrl)}
                                        >
                                            <FontAwesomeIcon icon={faCopy} />
                                        </button>
                                        <a className="input input-sm input-bordered" onClick={() => copyToClipboard(shortUrl)} > {shortUrl} </a>
                                    </div>
                                    <span className="indicator-item badge badge-primary">new</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="container mx-auto">
                <h2 className="text-2xl font-bold text-center">My Links</h2>
                <div className="overflow-x-auto">
                    <table className="table table-compact w-full mb-2">
                        <thead>
                            <tr>
                                <th>
                                    <button className="btn btn-square btn-sm btn-outline" onClick={fetchData}>
                                        <FontAwesomeIcon icon={faArrowsRotate} />
                                    </button>
                                </th>
                                <th>Short URL</th>
                                <th>Long URL</th>
                                <th>Clicks</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(data).map((key) => (
                                <>
                                    <tr key={key} className="hover">
                                        <td className="w-1/6">
                                            <button
                                                className="btn btn-sm btn-ghost btn-info ml-2"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setExpandedKey(expandedKey === key ? null : key);
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faCircleInfo} />
                                            </button>
                                            <button className="btn btn-sm btn-ghost btn-error ml-2" onClick={() => handleDelete(key)} >
                                                <FontAwesomeIcon icon={faTrash} />
                                            </button>
                                        </td>
                                        <td className="input-group">
                                            <button
                                                className="btn btn-sm btn-outline"
                                                onClick={() => copyToClipboard(`https://ugh.lol/${key}`)}
                                            >
                                                <FontAwesomeIcon icon={faCopy} />
                                            </button>
                                            <a className="input input-sm input-bordered w-full" onClick={() => copyToClipboard(`https://ugh.lol/${key}`)} >https://ugh.lol/{key}</a>
                                        </td>
                                        <td className="w-2/6">
                                            <div className="truncate w-[200px]">
                                                <a href={data[key].url}>{data[key].url}</a>
                                            </div>
                                        </td>

                                        <td className="w-1/6">
                                            {data[key].clicks}
                                        </td>

                                    </tr>
                                    {expandedKey === key && (
                                        <tr>
                                            <td></td>
                                            <td colSpan="2">
                                                <div className="stats shadow">
                                                    <div className="stat">
                                                        <div className="stat-title">Clicks</div>
                                                        <div className="stat-value text-primary"> {data[key].clicks} </div>
                                                        <div className="stat-desc">All time</div>
                                                    </div>
                                                    <div className="stat">
                                                        <div className="stat-title">Expires In</div>
                                                        <div className="stat-value text-primary">0</div>
                                                        <div className="stat-desc">days</div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </>
                            ))}
                        </tbody>


                    </table>
                </div>
            </div>
            {showToast && (
                <div className="toast toast-top toast-center">
                    <div className="alert alert-secondary">
                        <div>
                            <span>Copied to clipboard!</span>
                        </div>
                    </div>
                </div>
            )}

        </div>


    );
}

export default Links;

